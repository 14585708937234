@import url(https://fonts.googleapis.com/css?family=Raleway:200,400,600,800,800i,900&display=swap);
body {
  margin: 0;
  margin-top: -25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
}

html {
  font-family:'Raleway', sans-serif;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Color classes */

.dark-bg {
  background: #000014;
}

.yellow-bg {
  background: #F8EFE7;
}

.yellow-dark-bg {
  background: #C2946D;
}

.text-light {
  color: white;
}

.active {
  font-weight: 800;
}

.active span {
  font-weight: 400;
}
/*  Alignment classes */
.d-flex {
  display: flex;
}

.d-block {
  display: block !important;
}

.wrap {
  flex-wrap: wrap;
}

.centered {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.pl-2 {
  margin-left: 1em;
}

.pt-2 {
  margin-top: 2em;
}

#about-area {
  max-width: 800px;
  padding: 15px;
}

#contact-social-box {
  padding: 100px 1em 1em 4em;
}

#contact-social-box svg {
  padding-right: 27px;
}

#contact-social-box svg:hover {
  opacity: 0.6;
}

.container {
  width: 90vw;
}
